import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressSiteMetadata {
          name
        }
        wordpressWpApiMenusMenusItems(name: { eq: "Main Menu" }) {
          items {
            title
            object_slug
          }
        }
      }
    `}
    render={data => (
      <header className="navbar">
        <div className="container">
          <div className="navbar-brand">
            <Link to={`/`} className="navbar-item is-size-5-desktop is-dark">
              {data.wordpressSiteMetadata.name}
            </Link>
            <span
              className="navbar-burger burger"
              data-target="navbarMenuHeroC"
            >
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div id="navbarMenuHeroC" className="navbar-menu">
            <div className="navbar-end">
              <a href="/parts" className="navbar-item button">
                Find Parts
              </a>
              {data.wordpressWpApiMenusMenusItems.items.map(item => (
                <Link
                  key={item.object_slug}
                  to={`/${item.object_slug}`}
                  className="navbar-item button"
                >
                  {item.title}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </header>
    )}
  />
)

export default Header
